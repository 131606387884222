import React from 'react';
import styled from 'styled-components';

export default () => {
  return (
    <>
      <Wrapper>
        <div className="photoSection">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          ></div>
        </div>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .buttonSection {
    display: flex;
    padding: 0 3rem;
  }

  .photoSection {
    padding: 5rem 0;
  }

  .styledbutton {
    background: ${({ theme }) => theme.colors.goldPrimary};
    color: black;
    width: 200px;
    font-size: 1rem;
    height: 55px;
    font-weight: 500;
    margin: 5rem 0;
    border: none;
  }
`;
